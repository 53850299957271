<template>
  <Layout navbarTransparent>
    <Header :image="current.procat_image" :header="current.procat_desc" :header_eng="current.procat_desc_en" />
    <div class="container px-5">
      <div style="background-color: #f6f6f6" class="">
        <div class="pt-4">
          <div class="position-relative px-3">
            <div
              class="container position-relative bg-d"
              style="z-index: 0; margin-top: -12em"
            >
              <div class="row align-items-center my-5 py-0" style="padding: 0 6em">
                <div class="col-md-auto my-1 justify-content-center d-flex">
                  <img
                    :src="current.procat_content_image"
                    class="w-100"
                    style="max-width: 300px; border-radius: 30px; margin-right: 6em"
                  />
                </div>
                <div class="col-md my-2  ql-editor">
                  <div v-if="currentLocale == 'EN'" v-html="current.procat_content_en"></div>
                  <div v-else v-html="current.procat_content"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    

    <div class="container ql-editor py-4 mt-5 pt-5">
      <div class="d-flex flex-column">
        <div class="" v-if="currentLocale == 'EN'" v-html="current.procat_additional_1_en">What You Need</div>
        <div class="" v-else v-html="current.procat_additional_1">What You Need</div>
        <img src="@/static/assets/Group 676.png" class="mx-auto" />
      </div>

      <div v-if="list">
        <template v-for="item in list.section_1">
          <div class="row justify-content-center mt-3 align-items-center" :key="item.pl7s1_id">
              <div class="col-md-auto">
                <img
                    :src="item.pl7s1_image"
                    class="w-100"
                    style="max-width: 300px; border-radius: 30px;"
                  />
              </div>
              <div class="col-md-7">
                <div class="col-md my-2  ql-editor">
                  <div v-html="item.pl7s1_content"></div>
                </div>
              </div>
          </div>
        </template>
      </div>
    </div>


    <div style="background:#E8F1F8" class="py-4">
      <div class="container ql-editor">
        <div class="d-flex flex-column">
          <div class="" v-if="currentLocale == 'EN'"  v-html="current.procat_additional_2_en">What You Need</div>
          <div class="" v-else v-html="current.procat_additional_2">What You Need</div>
          <img src="@/static/assets/Group 676.png" class="mx-auto " />
        </div>
        <div v-if="list" class="mb-5 pb-5">
          <template v-for="item in list.section_2">
            <div class="row justify-content-center mt-3 align-items-center" :key="item.pl7s2_id">
                <div class="col-md-auto p-0">
                  <img
                      :src="item.pl7s2_image"
                      class="w-100 mx-auto"
                      style="max-width: 300px; border-radius: 30px; "
                    />
                </div>
                <div class="col-md-6">
                  <div class="col-md my-2  ql-editor">
                    <div v-html="item.pl7s2_content"></div>
                  </div>
                </div>
            </div>
          </template>
        </div>

        <Servicetron>
          <div v-if="currentLocale == 'EN'" v-html="current.procat_additional_3_en" class="">DON'T WAIT TO TRANSFORM! LET US HELP YOU</div>
          <div v-else v-html="current.procat_additional_3" class="">DON'T WAIT TO TRANSFORM! LET US HELP YOU</div>
          <router-link :to="'/product-services/'+current.procat_parent" style="">
            <div
              class="mt-1 mx-auto p-1 px-2 rounded-pill shadow fab-help"
              style="max-width: fit-content;"
            >
              <span v-if="currentLocale == 'EN'" v-html="current.procat_additional_4_en">CONTACT US</span>
              <span v-else v-html="current.procat_additional_4">CONTACT US</span>
            </div>
          </router-link>
        </Servicetron>
      </div>
    </div> 
  </Layout>
</template>

<script>
import Layout from "@/layouts/main.vue";
import Header from "./header.vue";
import Servicetron from "@/components/home/Servicetron.vue"; 

import store from "@/store";

import {
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BTabs,
  BTab,
  BEmbed,
} from "bootstrap-vue";

export default {
  components: {
    Header,
    Servicetron,

    Layout,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BTabs,
    BTab,
    BEmbed,
  },
  mounted() {
    this.getNextDatas();
  },
  computed: {
    currentLocale() {
      return this.$store.state.locales.CURRENT;
    },
  },
  watch: {
    currentLocale(val) {
      this.getNextDatas();
    },
  },
  methods: {
    async getNextDatas() {
      this.current = await store.dispatch("product_categories/getDataList", {
        id: this.$route.params.id,
      });
      this.list = await store.dispatch("product_categories/getDataLayout7", {
        id: this.$route.params.id,
        langcode: this.currentLocale,
      });
    },
  },
  data() {
    return {
      current: {},
      list: [],
    };
  },
};
</script>

<style>
.nav-tabs .nav-link.active {
  color: #ef6d22;
}
.nav-tabs .nav-link.active::after {
  background: #ef6d22 !important;
  height: 5px;
  border-radius: 3px;
}
.mask1 {
  -webkit-mask-image: linear-gradient(to top, transparent 0%, black 40%);
  mask-image: linear-gradient(to top, transparent 0%, black 90%);
}

.bg-d {
  border-radius: 20px;
  background-color: #fef1e9;
  /* background-image: url('@/static/assets/home/cscs 2.png'); */
}
.bg-home {
  -webkit-mask-image: linear-gradient(to bottom, transparent 0%, black 40%);
  mask-image: linear-gradient(to bottom, transparent 0%, black 0%);
  /* background-image:url('@/static/assets/Vector 41.png');  */
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
